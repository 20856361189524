import { useState, useEffect } from 'react';
import type { AppProps } from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import { ApolloProvider } from '@apollo/client';
import { SessionProvider } from 'next-auth/react';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { App as AntApp, ConfigProvider } from 'antd';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { StyleSheetManager } from 'styled-components';
import { SWRConfig } from 'swr';

import { LogoProvider } from 'context/LogoContext';
import { LoaderProvider } from 'context/LoaderContext';
import { WelcomeModalProvider } from 'context/WelcomeModalContext';
import { MenuProvider } from 'context/MenuContext';

import Header from 'components/Header';
import Footer from 'components/Footer';
import CookieBanner from 'components/CookieBanner';
import Providers from 'components/Providers';
import Loader from 'components/Loader';
import DefaultSeo from 'components/SEO/Default';
import { useApollo } from 'lib/apollo/client';
import {
  cleanupBrevoConversations,
  initBrevoConversations,
} from 'lib/brevo/conversations';
import { cleanupBrevoSDK, initBrevoSDK } from 'lib/brevo/tracker';

import GlobalStyles from 'styles/global';
import antdTheme from 'styles/antdTheme';

import 'styles/fonts.css';
import 'styles/nprogress.css';

if (process.env.NODE_ENV === 'development') {
  loadDevMessages();
  loadErrorMessages();
}

if (typeof window !== 'undefined') {
  import('utils/cookieBannerStorage').then(({ default: storage }) => {
    const { cookiesEnabled } = storage.getData();

    posthog.init(process.env.POSTHOG_KEY, {
      api_host: process.env.POSTHOG_HOST || 'https://eu.i.posthog.com',
      person_profiles: 'always',
      persistence: cookiesEnabled ? 'localStorage+cookie' : 'memory',
      debug: false,
      loaded: (posthog) => {
        posthog.debug(false);
      },
    });
  });
}

const App = ({ Component, pageProps, router }: AppProps) => {
  const apolloClient = useApollo(pageProps.initialApolloState);
  const isHomePage = router.route === '/';
  const isOppProject = router.route.includes('/opp/projects/');

  const [currentPath, setCurrentPath] = useState(router.route);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    const { referralCode } = router.query;
    if (referralCode) {
      localStorage.setItem('Shuttle-referralCode', referralCode as string);
    }
  }, [router.query]);

  useEffect(() => {
    if (pageProps.session?.user) {
      posthog.identify(pageProps.session?.user?.id, {
        email: pageProps.session?.user?.email,
      });
    }
  }, [pageProps.session?.user]);

  useEffect(() => {
    if (!pageProps.session?.user?.email || typeof window === 'undefined') {
      return;
    }

    const userEmail = pageProps.session.user.email;

    if (window.BrevoConversations) {
      window.BrevoConversations('updateIntegrationData', {
        email: userEmail,
      });
    }

    const brevo = window.Brevo;
    if (brevo && 'identify' in brevo) {
      brevo.push(function () {
        brevo.identify({
          identifiers: {
            email_id: userEmail,
          },
        });
      });
    }
  }, [pageProps.session?.user?.email]);

  useEffect(() => {
    const startURLChange = (path) => {
      if (window.location.pathname !== path.split('?')[0]) {
        NProgress.start();
      }

      if (path.split('?')[0] === '/' && isInitialLoad) {
        setIsInitialLoad(false);
      }
    };

    const endURLChange = () => {
      NProgress.done();
      if (window.location.pathname !== currentPath.split('?')[0]) {
        window.scrollTo(0, 0);
        setCurrentPath(router.route);
      }
    };

    Router.events.on('routeChangeStart', startURLChange);
    Router.events.on('routeChangeComplete', endURLChange);
    Router.events.on('routeChangeError', endURLChange);

    return () => {
      Router.events.off('routeChangeStart', startURLChange);
      Router.events.off('routeChangeComplete', endURLChange);
      Router.events.off('routeChangeError', endURLChange);
    };
  }, []);

  useEffect(() => {
    const conversationsScript = initBrevoConversations(pageProps.session);
    return () => {
      if (conversationsScript && conversationsScript.parentNode) {
        conversationsScript.parentNode.removeChild(conversationsScript);
      }
      cleanupBrevoConversations();
    };
  }, [pageProps.session]);

  useEffect(() => {
    const sdkScript = initBrevoSDK();
    return () => {
      if (sdkScript && sdkScript.parentNode) {
        sdkScript.parentNode.removeChild(sdkScript);
      }
      cleanupBrevoSDK();
    };
  }, []);

  return (
    <>
      <DefaultSeo />
      <Providers
        providers={[
          {
            ProviderComponent: StyleSheetManager,
            props: {
              enableVendorPrefixes: true,
            },
          },
          {
            ProviderComponent: PostHogProvider,
            props: { client: posthog },
          },
          {
            ProviderComponent: ApolloProvider,
            props: { client: apolloClient },
          },
          {
            ProviderComponent: SessionProvider,
            props: { session: pageProps.session },
          },
          {
            ProviderComponent: ConfigProvider,
            props: { theme: antdTheme },
          },
          { ProviderComponent: LogoProvider },
          { ProviderComponent: LoaderProvider },
          { ProviderComponent: WelcomeModalProvider },
          { ProviderComponent: MenuProvider },
        ]}
      >
        <AntApp>
          <SWRConfig
            value={{
              fallback: pageProps.fallback || {},
              fetcher: (url) => fetch(url).then((res) => res.json()),
              revalidateOnFocus: false,
              revalidateOnReconnect: true,
              // Cache responses for 5 minutes
              dedupingInterval: 5 * 60 * 1000,
              shouldRetryOnError: true,
              errorRetryCount: 2,
            }}
          >
            <GlobalStyles />
            {!isOppProject && <Header />}
            {isHomePage && (
              <Loader progress={0} isInitialLoad={isInitialLoad} />
            )}
            <Component key={router.route} router={router} {...pageProps} />
            {!isOppProject && currentPath !== '/' && (
              <Footer $homepage={false} />
            )}
            {!isOppProject && (
              <CookieBanner cookieBanner={pageProps.cookieBanner} />
            )}
          </SWRConfig>
        </AntApp>
      </Providers>
    </>
  );
};

export default App;
